.active-class li{
    
    color: red;
    transition-duration: 0.1s;
}
.setting{
    background-color: #f0eeee;
    cursor: pointer;
    color: #2C2E43;
    
}
.notification{
    background-color: #f0eeee;
    cursor: pointer;
    color: #2C2E43;
    
}
.notification-bell{
    /* margin-right: 20px;
    padding: 0px; */
    /* position:relative; */
    /* right: -40px; */
}

@media only screen and (max-width: 600px) {
    .notification{
        display: none;
    }
    .setting{
           float: right;
           clear: both;
            
        }
    .notification-bell{
        /* position: relative; */
        /* right: -30px; */
        display: none;
        
        }
}

.unauth-container{
    display: flex;
    justify-content: flex-end;
}