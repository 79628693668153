.fixed-bar{
    position: sticky;
    right: 20px;
    top:100px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline;
    width: 17%;
    background-color: rgb(167, 1, 1);
    cursor: pointer;
  }
  
  .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
  }
  
  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
  .file-box{
      width: 20%;
      padding: 10px;
      background-color: rgb(255, 255, 255);
      border-radius: 5px;
      box-shadow: 1px 1px 4px 1px rgba(0,0,0,0.2);
      border: 1px solid rgb(209, 208, 208);
      cursor: pointer;
      float: left;
  }

.excel-button{
  background: #d63031;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 5px;
  /* background-color: rgb(1, 11, 4); */
}
.excel-button:hover{
  background: #ee6161;
  transition: .8s;

}
@media only screen and (max-width: 600px) {
  .excel-button{
    display: none;
  }
}
@media only screen and (max-width: 600px) {
    .fixed-bar{
        /* position: relative; */
        /* margin-left: 20px; */
        margin: 20px;
        
    }
}
.red {
  background-color: rgb(248, 210, 210);
  color:rgb(6, 53, 94);
  
}
