.ant-btn-primary {
    background: #fff;
    color: #000;
    border: 1px solid rgb(218, 209, 209);
    outline: none;
   
}
@media only screen and (max-width: 992px) {
    .ant-btn-primary {
       padding: 0px 5px;
       font-size: 14px;
    }
}
.ant-btn-primary:hover,
.ant-btn-primary:focus ,
.ant-btn-primary:active  {
    background: #36B66A;
    color: #fff;
    border: 1px solid #36B66A;
}

.site-layout .site-layout-background {
  background: #fff;
}
  [data-theme="dark"] .site-layout .site-layout-background {
    background: #36B66A;
  }
  .site-layout{
    background-color: #fff;
    
  }

/* .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #ED1C24;
    border-left-color: #ED1C24;
  }
  .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9;
  }
  .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #ED1C24;
  } */